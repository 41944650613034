import { useRouter } from 'next/router';
import queryString from 'query-string';
import React from 'react';

export default function useQueryArgs (): Object {
  const router = useRouter();

  // rendered on the server or first render on client, this isn't available
  // TOOD: reevaluate this if/when SSR becomes important
  if (router.isReady) return router.query;
  else if (process.browser) return queryString.parse(window.location.search);
  return {};
}
