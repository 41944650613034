import cn from 'classnames';
import React from 'react';

import Loader from './Loader';
import Highlight from './Highlight';
import Header from './Header';
import NewStepper from './NewStepper';

export default function SingleStep ({
  activeStep,
  children,
  first,
  firstStep,
  highlight,
  image,
  isLoading,
  prefix,
  steps,
  suffix,
  title,
  toast,
  wide,
}) {

  const renderTitle = () => {
    return highlight ? (
      <Highlight className="title" content={title} highlight={highlight} />
    ) : (
      <>
        <p className="title">{title}</p>
        <style jsx>{`
          @media (max-width: 800px) {
            .title {
              font-size: 16px;
            }
          }
        `}</style>
      </>
    );
  };

  const renderPrefix = () => {
    return highlight ? (
      <Highlight className="prefix" content={prefix} highlight={highlight} />
    ) : (
      <p className="prefix">{prefix}</p>
    );
  };

  const renderSuffix = () => {
    return <p className="suffix">{suffix}</p>;
  };

  return (
    <>
      <Header firstStep={firstStep} first={first} />
      <main className={cn({ wide })}>
        <div className="content">
          {isLoading ? <Loader /> : (
            <>
              {typeof steps !== "undefined" &&
              typeof activeStep !== "undefined" ? (
                <div className="stepper-holder">
                  <NewStepper steps={steps} activeStep={activeStep} />
                </div>
              ) : null}
              <div className="heading">
                {toast && <p className="title">{toast}</p>}
                {prefix && renderPrefix()}
                {title && renderTitle()}
                {suffix && renderSuffix()}
              </div>
              {image && (
                <figure>
                  <img src={image.src} alt={image.alt} />
                </figure>
              )}
              {children}
            </>
          )}
        </div>
        <style jsx>{`
          main {
            display: block;
            max-width: 700px;
            margin: 0 auto;
          }
          main.wide {
            max-width: 100%;
          }

          .stepper-holder {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
          }
          .error {
            height: 65px;
            margin: 0;
            padding: 1em 0;
            text-align: center;
          }
          .content {
            max-width: 100%;
            margin: 0 auto;
          }
          p {
            font-size: 19px;
            text-align: center;
            font-weight: 700;
            line-height: 1.44;
          }
          p.title + p.title {
            margin-top: 0;
          }

          .heading {
            max-width: 388px;
            margin: 0 auto 36px;
          }
          figure {
            margin: 0;
            text-align: center;
          }
          img {
            max-width: 65%;
          }

          @media (min-width: 1200px) {
            .stepper-holder {
              display: none;
            }
          }

          @media (max-width: 800px) {
            main:not(.wide) {
              padding: 0 30px;
            }
            .content {
              max-width: 100%;
            }
          }
        `}</style>
        <style jsx global>
          {`
            p.prefix,
            p.title,
            p.suffix,
            span.title,
            span.prefix,
            span.suffix {
              display: block;
              text-align: center;
              font-family: "Poppins", -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
              font-size: 19px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.37;
              letter-spacing: 0.59px;
              color: #313146;
            }

            p.prefix {
              margin-bottom: 0;
            }

            p.prefix + p.title {
              margin-top: 0.5em;
              font-size: 1.4rem;
            }

            p.suffix,
            span.suffix {
              font-size: 1em;
              margin-top: -0.5em;
            }

            span.title mark {
              color: #41ef8b !important;
            }
          `}
        </style>
      </main>
    </>
  );
}
