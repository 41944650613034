export const step4 = [
  {
    title: "Why does Common Energy need my utility account information?",
    content: `We need your utility account so we can connect your account to the
    community solar project. The project will generate clean energy
    credits that lower your electricity bill each month.`
  },
  {
    title: "Is my electricity service impacted?",
    content: `No. Your home will receive electricity exactly as it does today,
        directly from your utility. The only difference is you will pay
        less.`
  }
];

export const step7 = [
  {
    title: "How will I save money?",
    content: `Each month you will receive clean energy credits that lower your
        electricity bill.`
  },
  {
    title: "Why does Common Energy need a payment method?",
    content: `Going forward instead of paying your utility, you will pay Common
          Energy a lower amount, and Common Energy will pay your utility on
          your behalf.`
  },
  {
    title: "What if I want to cancel?",
    content: `Common Energy has thousands of customers and stands by our impact
          and savings. If you want to cancel, you may do so at any time at no
          cost.`
  }
];

export const step8_creditCard = [
  {
    title: "How will my credit card information be used?",
    content: `Your credit card information will be used to pay for your monthly
        utility bill, the only difference is you will pay less every month.`
  },
  {
    title: "Is my information secure?",
    content: `Yes. All of your information is saved with 128-bit encryption.`
  }
];

export const step8_manualBanking = [
  {
    title: "How will my banking information be used?",
    content: `Your banking information will be used to pay for your monthly
        utility bill, the only difference is you will pay less every month.`
  },
  {
    title: "Is my information secure?",
    content: `Yes. All of your information is saved with 128-bit encryption.`
  }
];

export default {
  step4,
  step7,
  step8_creditCard,
  step8_manualBanking
};
