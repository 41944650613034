import React, { useRef, useEffect } from "react";
import cn from "classnames";

export default function ModalPopup({
  children,
  className,
  setOpenModal,
  open = false
}) {
  const modalRef = useRef(null);

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => setOpenModal(open), [open]);

  return (
    <div
      className={cn("modal", {
        "show-modal": open,
        "hidden-modal": !open
      })}
    >
      <div
        className={cn("modal-content", { className: !!className })}
        ref={modalRef}
      >
        {children}
      </div>
      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          z-index: 999;
          transition: all 0.3s;
        }
        .modal-content {
          position: fixed;
          background: white;
          width: 80%;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
        }
        .show-modal {
          display: block;
        }
        .hidden-modal {
          display: none;
        }
        @media (max-width: 320px) {
          .modal-content {
            width: 85%;
          }
        }
      `}</style>
    </div>
  );
}
