import Refresh from '@material-ui/icons/Refresh';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector, useStore } from 'react-redux';

import { getOnboarding } from '../store/selectors';

import stepData from './modalFAQ';
import useQueryArgs from '../lib/hooks/useQueryArgs';
import { reset } from '../lib/onboarding';

import LogoIcon from './Icons/LogoIcon';
import Modal from './ModalPopup';
import ProfilePic from './ProfilePic';

export default function Header ({ first, firstStep }) {
  const router = useRouter();
  const store = useStore();
  const { paymentMethod } = useQueryArgs();
  const { spend } = useSelector(getOnboarding);
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickSignout = React.useCallback(() => {
    return reset({ store });
  }, [ store ]);

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const renderModal = () => {
    const step = router.pathname.split('/')[2];

    const renderContent = () => {
      if (step === 'step8' && paymentMethod === '') {
        return null;
      }

      const suffix = step === 'step8' ? `_${paymentMethod}` : '';

      return stepData[step + suffix]?.map(data => {
        return (
          <div key={data.title}>
            <h3 className="info-heading">{data.title}</h3>
            <p className="info-content">{data.content}</p>
            <hr className="info-separator" />
            <style jsx>{`
              .info-heading {
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.51px;
                color: #161621;
                margin-bottom: 10px;
                margin-top: 0;
              }

              .info-content {
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: 0.4px;
                margin: 0;
              }

              .info-separator {
                margin: 27px 0;
                width: 100%;
                background-color: transparent;
                opacity: 0.13;
                border: solid 1.5px #555e80;
              }

              .info-separator:last-of-type {
                display: none;
              }
            `}</style>
          </div>
        );
      });
    };

    const renderSavings = () => {
      const cleanEnergy = ((spend - 10) / 0.1) * 0.9;
      const savings = cleanEnergy * 0.01;

      return (
        <>
          <h3 className="info-heading">
            You're three steps away from clean energy and a lower power bill:
          </h3>
          <div className="bill">
            <h4 className="bill-title">Common Energy</h4>
            <hr />
            <div className="bill-rows">
              <div className="bill-row">
                <p className="bill-subtitle">Original Bill</p>
                <p className="bill-amount">${spend.toFixed(0)}</p>
              </div>
              <div className="bill-row">
                <p className="bill-subtitle">Credit</p>
                <p className="bill-amount">-${savings.toFixed(0)}</p>
              </div>
            </div>
            <p className="new-total">New Total</p>
            <p className="new-bill">${(spend - savings).toFixed(0)}</p>
          </div>
          <style jsx>{`
            .info-heading {
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.51px;
              color: #161621;
              margin-bottom: 10px;
            }

            .bill {
              margin-top: 19px;
              padding: 20px 40px;
              border: solid 1.5px #f1f1f1;
              text-align: center;
              max-width: 246px;
              margin: 0 auto;
            }

            .bill p {
              color: #2479ff;
              margin: 0;
            }

            .bill hr {
              margin-top: 4px;
              margin-bottom: 20px;
            }

            .bill-title {
              font-size: 17px;
              font-weight: bold;
              line-height: 2.35;
              color: #161621;
              letter-spacing: normal;
              margin-bottom: 0;
            }

            .bill-rows {
              border-bottom: solid 1.5px #f3f3f3;
              padding-bottom: 12px;
              margin-bottom: 15px;
            }

            .bill-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .bill-amount {
              letter-spacing: normal;
              font-size: 19px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: normal;
            }

            .bill-subtitle {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.36;
              letter-spacing: 0.44px;
              color: #555e80 !important;
            }

            .new-total {
              font-size: 14px;
              line-height: 1.36;
              letter-spacing: 0.44px;
              color: #555e80 !important;
            }

            .new-bill {
              font-size: 41px;
              font-weight: 600;
              line-height: 1.4;
            }
          `}</style>
        </>
      );
    };

    return (
      <div>
        <div className="modal-header">
          <span className="modal-title">FAQs</span>
          <button className="exit-modal" onClick={handleCloseModal}>
            ×
          </button>
        </div>
        <div className="modal-body">
          {step === 'step3' ? renderSavings() : renderContent()}
        </div>
        <style jsx>{`
          .modal-body {
            flex-direction: column;
            overflow-y: auto;
            padding: 38px 32px;
          }
        `}</style>
      </div>
    );
  };

  const renderRestart = () => {
    const step = router.pathname.split('/')[2];
    const showQuestionMark =
      step === 'step3' ||
      step === 'step4' ||
      step === 'step7' ||
      step === 'step8';
    return (
      <div className="wrapper">
        {showQuestionMark && (
          <button className="question-mark" onClick={handleOpenModal}>
            <HelpOutline style={{ height: 24, width: 24, fontSize: 24 }} />
            <span className="tooltip">FAQs</span>
          </button>
        )}

        <button onClick={handleClickSignout}>
          <Refresh style={{ height: 24, width: 24, fontSize: 24 }} />
          <span className="tooltip">Start Over</span>
        </button>
        <Modal open={openModal} setOpenModal={status => setOpenModal(status)}>
          {renderModal(handleCloseModal)}
        </Modal>
        <style jsx>{`
          .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 180px;
          }

          .tooltip {
            display: none;
            font-size: 10px;
            width: 65px;
            text-align: center;
            color: #fff;
            background-color: #4a4a4a;
            padding: 3px 6px;
            border-radius: 2px;
            position: absolute;
            left: -21px;
            top: -15px;
          }

          .tooltip:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(74, 74, 74, 0);
            border-top-color: #4a4a4a;
            border-width: 3px;
            margin-left: -3px;
          }

          button:hover .tooltip {
            display: block;
          }

          button {
            display: block;
            background: none;
            border: none;
            cursor: pointer;
            color: #2479ff;
            padding: 0;
            font-size: 32px;
            position: relative;
          }

          .question-mark {
            margin-right: 19px;
          }

          @media (min-width: 1200px) {
            .question-mark {
              display: none;
            }
          }

          @media (max-width: 1200px) {
            .wrapper {
              margin-right: 35px;
            }
          }

          @media (max-width: 800px) {
            .wrapper {
              margin-right: 0;
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <header className={!first ? 'header-onboarding' : null}>
      {first ? (
        <div className="logo-holder logo-holder--without-img">
          <LogoIcon nomargin />
        </div>
      ) : (
        <div className="logo-holder logo-holder--with-img">
          <LogoIcon nomargin />
        </div>
      )}
      {!first && !firstStep && renderRestart()}
      {!first && (
        <div className="bubble">
          <ProfilePic
            image={{
              src: '/static/images/people/martin.jpg',
              altText: 'A smiling girl',
            }}
          />
        </div>
      )}
      <style jsx>{`
        header {
          position: relative;
          width: 100%;
          border-bottom: 3px solid #41ef8b;
          margin-top: 40px;
          margin-bottom: 3.5rem;
          z-index: 11;
        }

        .header-onboarding {
          padding: 0;
          margin-bottom: 70px;
          display: flex;
          justify-content: space-between;
        }

        .logo-holder {
          margin-bottom: 16px;
        }

        .logo-holder--with-img {
          width: 176px;
          margin-left: 200px;
        }

        .logo-holder--without-img {
          width: 250px;
          margin-left: auto;
          margin-right: auto;
        }

        .bubble {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 50%);
          bottom: 0;
          pointer-events: none;
        }

        @media (max-width: 1200px) {
          .logo-holder--with-img {
            margin-left: 35px;
          }
        }

        @media (max-width: 800px) {
          .header-onboarding {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 30px;
            width: auto;
          }

          .logo-holder--with-img {
            margin-left: 5px;
            width: 100px;
          }
        }
      `}</style>
    </header>
  );
}
