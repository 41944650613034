import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import cn from 'classnames'

const useCustomStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    border: "2px solid #2479ff",
    zIndex: 1,
    color: '#2479ff',
    width: 29,
    height: 29,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#2479ff',
    color: "#fff"
  },
  completed: {
    backgroundColor: '#2479ff',
    color: "#fff"
  },
});

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  const { active, completed, icon } = props;
  return (
    <div
      className={cn(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
}

const CustomStep = withStyles({
  alternativeLabel : {
    flex: '0 1 auto'
  },
  horizontal: {
    paddingLeft: 12,
    paddingRight: 12,
    '&:first-of-type':{
      paddingLeft:0
    }
  }
})(Step);

const CustomStepLabel = withStyles({
  root:{
    fontSize: 14
  },
  labelContainer: {
    display: 'none'
  }

})(StepLabel);

const CustomStepper = withStyles({
  root: {
    padding: 0,
    background: "transparent"
  }
})(Stepper);

const CustomConnector = withStyles({
  root: {
    top: 14,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)"
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#2479ff',
    borderRadius: 1,
  },
})(StepConnector);

export default function NewStepper({steps,activeStep }) {

  return <CustomStepper activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
    {steps.map((label) => (
      <CustomStep key={label}>
        <CustomStepLabel
          StepIconComponent={CustomStepIcon}
        >
          {label}
        </CustomStepLabel>
      </CustomStep>
    ))}
  </CustomStepper>
}
